import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import theme from '../../src/theme';

export default function TopLayout(props) {
	return (
		<>
			<Helmet>
				<meta
					name="viewport"
					content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
				/>
				<link
					href="https://fonts.googleapis.com/css?family=Raleway:300,400,700,900"
					rel="stylesheet"
				/>
			</Helmet>
			<GoogleReCaptchaProvider
				reCaptchaKey={process.env.GATSBY_GOOGLE_RECAPTCHA_KEY}
			>
				<ThemeProvider theme={theme}>
					{/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
					<CssBaseline />
					{props.children}
				</ThemeProvider>
			</GoogleReCaptchaProvider>
		</>
	);
}

TopLayout.propTypes = {
	children: PropTypes.node,
};
