import {
	blue,
	deepOrange,
	green,
	grey,
	purple,
	red,
	teal,
	yellow,
} from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
	boxShadow: `0 5px 5px rgba(0, 0, 0, 0.15)`,
	layers: {
		autocompleteResults: 13,
		chatWidget: 999998,
		content: 11,
		fixedContent: 12,
	},
	palette: {
		brand: {
			brown: '#583b3b',
			gold: '#fed54f',
			green: '#c0cb32',
			orange: '#f78f1e',
			pink: '#f48981',
			salmon: '#f36f46',
			teal: '#28a79a',
		},
		error: red,
		grey: grey,
		info: blue,
		primary: process.env.NODE_ENV === 'production' ? teal : purple,
		secondary: deepOrange,
		success: green,
		text: {
			primary: '#583b3b',
		},
		warning: yellow,
	},
	shape: {
		borderRadius: 0,
	},
	sizes: {
		componentSpacing: 64, // pixels of spacing above and below major components
		contentMaxWidth: 1312, // 1280 + 4x spacing for 2x padding on either size
		gutter: 16,
		headerHeight: 95,
		narrowContentMaxWidth: 992, // 960 + 4x spacing for 2x padding on either size
		topBorderWidth: 16,
		photos: {
			extraSmall: 400,
			small: 700,
			medium: 960,
			large: 1280,
		},
		socialSharingImage: {
			height: 630,
			width: 1200,
		},
	},
	typography: {
		button: {
			fontWeight: 900,
			letterSpacing: 1,
		},
		fontFamily: 'Raleway, sans-serif',
		fontSize: 16,
		h5: {
			fontSize: 18,
			fontWeight: 700,
		},
		useNextVariants: true,
	},
});

theme.typography.h1 = {
	fontSize: 36,
	fontWeight: 300,
	lineHeight: 1,
	[theme.breakpoints.up('sm')]: {
		fontSize: 48,
	},
};

theme.typography.h2 = {
	fontSize: 24,
	fontWeight: 700,
	lineHeight: 1,
	[theme.breakpoints.up('sm')]: {
		fontSize: 30,
	},
};

theme.typography.h3 = {
	fontSize: 24,
	fontWeight: 300,
	lineHeight: 1,
	[theme.breakpoints.up('sm')]: {
		fontSize: 30,
	},
};

theme.typography.h4 = {
	fontSize: 18,
	fontWeight: 700,
	[theme.breakpoints.up('sm')]: {
		fontSize: 24,
	},
};

theme.typography.h6 = {
	color: theme.palette.grey[600],
	fontSize: 14,
	fontWeight: 700,
};

theme.typography.body1 = {
	fontSize: 14,
	[theme.breakpoints.up('sm')]: {
		fontSize: 16,
	},
};

export default theme;
