// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-editor-js": () => import("./../../../src/pages/editor.js" /* webpackChunkName: "component---src-pages-editor-js" */),
  "component---src-pages-get-started-js": () => import("./../../../src/pages/get-started.js" /* webpackChunkName: "component---src-pages-get-started-js" */),
  "component---src-pages-partner-with-us-js": () => import("./../../../src/pages/partner-with-us.js" /* webpackChunkName: "component---src-pages-partner-with-us-js" */),
  "component---src-pages-the-restaurants-js": () => import("./../../../src/pages/the-restaurants.js" /* webpackChunkName: "component---src-pages-the-restaurants-js" */),
  "component---src-templates-partner-landing-page-js": () => import("./../../../src/templates/partner-landing-page.js" /* webpackChunkName: "component---src-templates-partner-landing-page-js" */),
  "component---src-templates-storyblok-entry-js": () => import("./../../../src/templates/storyblok-entry.js" /* webpackChunkName: "component---src-templates-storyblok-entry-js" */)
}

